.mission-vision-section__large {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.mission-vision-section__small {
  @media screen and (min-width: 769px) {
    display: none;
  }
}